(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.statistics.controller:StatisticsCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.statistics')
    .controller('StatisticsCtrl', StatisticsCtrl);

  function StatisticsCtrl() {
    var vm = this;
    vm.ctrlName = 'StatisticsCtrl';
  }
}());
